import * as MixPanel         from '../mixpanel';
import { getShortDate }      from '../time-helpers';
import * as Actions          from '../../context/ctx-actions';
import { useGlobalCtx }      from '../../context/ctx-hook';
import * as ProductSelectors from '../../context/selectors/product-selectors';
import { getUserId }         from '../../context/selectors/user-selectors';
// import { checkRebrandName }  from '../../helpers/pure-functions';
import {
  filterSimpSubs,
  createSubscriptionMap,
  transformHistoryMap,
  checkRebrandName,
} from '../pure-functions';

import * as NetworkCalls     from '../../network/network-calls';

export const useAutoRenew = (product, autoRenew = false, onClose) => {
  const {globalDispatch, state} = useGlobalCtx();
  const SUB = ProductSelectors.getSubscription(state, product);

  return async () => {
    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));

      const {data} = await NetworkCalls.callUpdateAutoRenew({
        product,
        userId: getUserId(state),
        autoRenew
      });

      let SUB_NAME = data.postState.productName;
      if (SUB_NAME == 'Simplifi') {
        SUB_NAME = ProductSelectors.simplifiTierDisplayName(SUB.tierName, 'fullname');
      } else {
        checkRebrandName(SUB_NAME);
      }

      MixPanel.track(MixPanel.MIX_PANEL_IDS.AUTO_RENEW);

      if(data?.postState) {
        // const updatedHistory = await NetworkCalls.callGetHistories();
        // const HISTORY_MAP = transformHistoryMap(updatedHistory);

        const subResponseYetAgain = await NetworkCalls.callGetSubscriptions();
        const SUB_MAP = filterSimpSubs(createSubscriptionMap(subResponseYetAgain.data, state.country));

        globalDispatch(Actions.mergeMainStateObj({
          isDisabled: false,
          // subscriptions: ProductSelectors.mergeSubscription(state, product, data.postState),
          subscriptions: SUB_MAP,
          // history: HISTORY_MAP,
          alert: {
            type: 'SUCCESS',
            messages: autoRenew
              ? [
                'Your membership is now set up!',
                `Your ${SUB_NAME} renewal date is ${getShortDate(data.postState.expireOn)}`
              ] : [
                `You can continue using ${SUB_NAME} until your subscription expires.`,
                'You can turn auto-renew on by clicking \'Set to Renew Automatically\' next to the product information.'
              ]
          },
        }));
      }


      onClose();
    } catch (err) {
      console.log(err);
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.AUTO_RENEW);
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  };
};
