import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S           from './paypal-form.module.scss';
import { TextField }    from '../../../common/inputs/text-field';
import * as Validations from '../../../../helpers/validations';

const validateZip = (country = '', zip) =>
  country.toUpperCase() === 'US'
    ? Validations.validateUSPostal(zip)
    : Validations.validateCAPostal(zip);


const PayPalForm = ({isVisible, values, errors, setForm, country, isDisabled}) => {

  const handleZipChange = React.useCallback(({type, target: {value}}) => {
    const IS_BLUR = type === 'blur';
    const IS_USA  = country === 'US';

    if (IS_BLUR) value = value.trim();
    value = IS_USA
      ? value.replace(/\D/g, '').substr(0, 5)
      : value.toUpperCase();

    const ERROR_MSG = validateZip(country, value);

    setForm(({values, errors}) => ({
      values: {...values, zipCode: value},
      errors: {
        ...errors,
        form:    '',
        zipCode: IS_BLUR
          ? ERROR_MSG === '' ? '' : errors.zipCode
          : ERROR_MSG
      }
    }));
  }, [setForm, country]);

  return (
    <article className={ClassNames(S.ppWrapper, {[S.show]: isVisible})}>
      <div className={S.ppBtnWrapper}>
        <div className={S.ppSide}>
          <div id="paypal-button"/>
          <p>Click above to link or update PayPal Account</p>
        </div>

        <div className={S.inputFields}>
          <TextField
            label="PayPal Account"
            name="paypalEmail"
            id="PP_EMAIL"
            placeholder="PayPal Id"
            error={null}
            value={values.email}
            disabled={true}
            onChange={() => {}}
            onBlur={() => {}}
          />
          <TextField
            label="Zip Code"
            name="zipCode"
            id="PP_ZIP_CODE"
            placeholder="Enter your zip code"
            error={errors.zipCode}
            value={values.zipCode}
            disabled={isDisabled}
            onChange={handleZipChange}
            onBlur={handleZipChange}
          />
        </div>
      </div>

      <p className={S.ppHeading}>You will be taken to the PayPal sign in screen. Sign in to complete your payment securely.</p>
      <p className={S.ppHeading}>This payment method will be set as your default payment method.</p>
      
    </article>
  );
};

PayPalForm.displayName = 'PayPalForm';
PayPalForm.propTypes   = {
  isDisabled: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setForm:   PropTypes.func.isRequired,
  country:   PropTypes.string.isRequired,
  values:    PropTypes.shape({
    zipCode: PropTypes.string,
    email:   PropTypes.string,
  }),
  errors:    PropTypes.shape({
    zipCode: PropTypes.string,
  })
};

export { PayPalForm };
