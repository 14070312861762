import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S                from './expiry.module.scss';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as ProductSelectors from '../../../context/selectors/product-selectors';
import { getProductsPaymentMethod } from '../../../context/selectors/user-selectors';
import { getFormattedDate }  from '../../../helpers/time-helpers';

function getExpiryText(SUB, hasPayment) {
  const active = SUB?.active || false;
  const autoRenew = SUB?.autoRenew || false;
  const inGracePeriod = SUB?.isInGracePeriod || false;

  if (active) {
    if (autoRenew) return hasPayment ? 'Renews' : 'Expires';
    else if (active && inGracePeriod) return 'Expired';
    else return 'Expires';
  } else if (!active) return 'Expired';
}

function getExpiryDate(SUB) {
  if(SUB?.active === true && SUB?.isInGracePeriod ) {
    return getFormattedDate(SUB?.expireOn);
  }
  if(SUB?.active === false) {
    return getFormattedDate(SUB?.inactiveAt);
  } else {
    return getFormattedDate(SUB?.expireOn);
  }
}

const Expiry = ({product, className, isInline}) => {
  const {state} = useGlobalCtx();
  const SUB     = ProductSelectors.getSubscription(state, product);

  const HAS_PAYMENT = getProductsPaymentMethod(product, state);

  return (
    <div
      data-testid="SUB_EXPIRY"
      className={ClassNames({
        [S.warning]: !SUB?.active || !SUB?.autoRenew,
        [S.inlineExpiry]: isInline,
        [S.expiry]:       !isInline,
        [className]:      className
      })}
    >{getExpiryText(SUB, HAS_PAYMENT)} {getExpiryDate(SUB)}</div>)
  ;
};

Expiry.displayName = 'Expiry';
Expiry.propTypes   = {
  product:   PropTypes.string.isRequired,
  className: PropTypes.string,
  isInline:  PropTypes.bool
};

Expiry.defaultProps = {
  isInline: false
};

export { Expiry };
