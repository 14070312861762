export const supportedProductLineUriNames = () => {
  return [
    'quicken-ca',
    'quicken-us',
    'acme',
    'acme-basic',
    'acme-hab',
    'acme-google',
    'acme-apple',
    'quicken-estate-manager',
    'billmanager',
    'quicken-online-backup'
  ];
};

export const createSubscriptionMap = (subList = []) => {
  let subMap = {};
  if (subList.length <= 0) return subMap;
  const productLines = supportedProductLineUriNames();

  subList.forEach(cur => {
    const PRODUCT = cur.productLineUriName.toLowerCase();
    if (productLines.includes(PRODUCT)) {
      subMap[PRODUCT] = cur;
    }
  });

  return subMap;
};

export const filterSimpSubs = (SUB_MAP) => {
  // If they have no inactive or active simplifi subscriptions just return the SUB_MAP
  const acmeSubs = ['acme', 'acme-basic', 'acme-google', 'acme-hab', 'acme-apple'];
  const hasSimp = Object.keys(SUB_MAP).some(r => acmeSubs.includes(r));
  if (!hasSimp) { return SUB_MAP; }

  // If there's any active subscription loop through and filter out expired subscriptions
  let hasActiveAcme = false;
  let hasInactiveAcme = false;
  let inactiveSimpCount = 0;
  Object.keys(SUB_MAP).forEach(k => {
    if (acmeSubs.includes(SUB_MAP[k].productLineUriName)) {
      if(SUB_MAP[k].active === true) {
        hasActiveAcme = true;
      }
      if(SUB_MAP[k].active === false) {
        inactiveSimpCount++;
        hasInactiveAcme = true;
      }
    }
  });

  // If any one acme is active and there are inactive, filter out the inactive subs from the map
  if(hasActiveAcme && hasInactiveAcme) {
    Object.keys(SUB_MAP).forEach(k => {
      if ((acmeSubs.includes(SUB_MAP[k].productLineUriName) && (SUB_MAP[k].active == false))) {
        delete SUB_MAP[k];
      }
    });
  }
  
  // If there's no active sub but there are 2 ore more inactive subs, filter out all but most recently expired
  if (!hasActiveAcme && hasInactiveAcme && inactiveSimpCount > 1) {
    const subMapArr = Object.keys(SUB_MAP).map((k) => SUB_MAP[k]);
    const mostRecentExp = new Date(Math.max(...subMapArr.map(e => new Date(e.expireAt))));
    Object.keys(SUB_MAP).forEach(k => {
      if (acmeSubs.includes(SUB_MAP[k].productLineUriName)) {
        if (new Date(SUB_MAP[k].expireAt).getTime() != mostRecentExp.getTime()) {
          delete SUB_MAP[k];
        }
      }
    });
  }

  return SUB_MAP;
};

export const transformHistoryMap = (historyApiRes) => {
  return historyApiRes.data.reduce((acm, { line, ...rest }) => {
    if (!acm[line]) acm[line] = [];
    return { ...acm, [line]: [...acm?.[line], rest] };
  }, {});
};

export const splitQuota   = quotaString =>
  quotaString?.split('|')?.reduce((acm, cur) => {
    const [name, count] = cur?.split('=');
    return ({...acm, [name]: count});
  }, {});

export const parseCookies = (cookie) =>
  cookie.split('; ').reduce((acm, cur) => {
    const [key, value] = cur.split('=');
    return {...acm, [key]: value};
  }, {});

export const setCookie = (cookieName, value, hours, explicitExpire=false) => {
  let cookieExpStr = '';
  if (explicitExpire) {
    cookieExpStr = explicitExpire;
  } else {
    const date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    cookieExpStr = date.toUTCString();
  }

  if (process.env.NODE_ENV === 'development') {
    // Don't specify domain on localhost, let it set default domain
    const cookieStr = `${cookieName}=${value}; expires=${cookieExpStr}; path=/;`;
    document.cookie = cookieStr;
  } else {
    const cookieStr = `${cookieName}=${value}; expires=${cookieExpStr}; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN};`;
    document.cookie = cookieStr;
  }
};

export const getProductLineBits = (subscriptions) => {
  const PRODUCTLINEBITS = {
    // Simplifi
    'acme-basic': 0b00000001,
    'acme': 0b00000001,
    'acme-hab': 0b00000001,
    'acme-google': 0b00000001,
    'acme-apple': 0b00000001,

    // Quicken
    'quicken-us': 0b00000010,
    'quicken-ca': 0b00000100,
    // 'billmanager': 0b00001000,
    
    // Estate Manager
    'quicken-estate-manager': 0b00001000,
    'qem': 0b00001000,

    // Online Backup
    'quicken-online-backup': 0b00010000
  };

  let mask = 0;
  subscriptions.forEach((sub) => {
    if (sub.active) {
      mask |= PRODUCTLINEBITS[sub.productLineUriName];
    }
  });
  return mask;
};

export const deleteCookie = cookieName => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN};`;
};

export const getTagLine = (tier = '') => {
  const tierTags = {
    'acme-basic': 'Powerfully simple, best for most users',
    'acme-hab': 'Stay organized & in control with all your finances in one place',

    'premier': 'Best-in-class investment tools, built-in tax reports & bill-pay',
    'deluxe': 'Robust & feature-rich, with in-depth analysis tools & customization.',
    'cash-manager': 'Start organizing your finances',
    'starter': 'Start organizing your finances',
    'hab': 'For your business, rental and personal finances',
    'hab-rpm': 'For your business, rental and personal finances',

    'billmanager': 'Pay your bills right from your app.',
    'basic': 'Pay your bills right from your app.',
    'lite': 'Pay your bills right from your app.',
    'plus': 'Pay your bills right from your app.',
    'volume50': 'Pay your bills right from your app.',
    'volume100': 'Pay your bills right from your app.',
    'volume200': 'Pay your bills right from your app.',
    'standard': 'Pay your bills right from your app.',
    'extra': 'Pay your bills right from your app.',

    'qem-basic': 'Life’s essential information, all in one place.',

    'qob-basic': 'Keep your data safe, no matter what.',
  };

  return tierTags[tier] ?? '';
};

export const checkForAnyErrors = errorList =>
  errorList ? errorList.some(val => val !== '') : false;

export const checkRebrandName = (productName) => {
  if (productName.toLowerCase().includes('quicken lifehub')) {
    return productName;
  } else if(productName.toLowerCase().includes('quicken')) {
    return productName + ' Classic';
  } else if (productName.toLowerCase().includes('simplifi')) {
    return 'Quicken '+ productName;
  } else {
    return productName;
  }
};

export const getFeatureFlag = (globalState, feature) =>{
  return ( globalState?.featureFlags.filter( (fElement) => (fElement.feature == feature && !fElement.feature_released) ).length > 0 );
};

// export const getIsMobile  = () => {
//   let check = false;
//   (function (a) {
//     //eslint-disable-next-line
//     if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
//       check = true;
//     }
//   })(navigator.userAgent || navigator.vendor || window.opera);
//   return check;
// };
