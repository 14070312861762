// Most this logic should be an API but here we are doing it in the frontend
const BM_ENTITLEMENT_TIERS = [
  'BILLPAY_STANDARD',
  'BILLPAY_EXTRA',
  'BILLPAY_PLUS',
  'BILLPAY_VOLUME50',
  'BILLPAY_VOLUME100',
  'BILLPAY_VOLUME200'
];

// const BM_PROMO_TIERS = [
//   'BILLPAY_STANDARD',
//   'BILLPAY_EXTRA'
// ];

// get tier data from entitlement string in subscription array
const BM_TIER_DATA =  {
  'BILLPAY_STANDARD': {
    'checkPay': 5,
    'quickPay': 12,
    'tierTitle': 'Bill Manager Standard',
    'tierName': 'Standard',
    'promoTierTitle': 'Promotion - Standard',
    'productLineName': 'Bill Manager',
    'tier': 'standard'
  },
  'BILLPAY_EXTRA': {
    'checkPay': 9,
    'quickPay': 12,
    'tierTitle': 'Bill Manager Plus',
    'tierName': 'Plus',
    'promoTierTitle': 'Promotion - Extra',
    'productLineName': 'Bill Manager',
    'tier': 'extra'
  },
  'BILLPAY_PLUS': {
    'checkPay': 15,
    'quickPay': 15,
    'tierTitle': 'Bill Manager Plus',
    'tierName': 'Plus',
    'promoTierTitle': 'N/A',
    'productLineName': 'Bill Manager',
    'tier': 'plus'
  },
  'BILLPAY_VOLUME50': {
    'checkPay': 25,
    'quickPay': 25,
    'tierTitle': 'Professional 50',
    'tierName': 'Professional 50',
    'promoTierTitle': 'N/A',
    'productLineName': 'Bill Manager',
    'tier': 'volume50'
  },
  'BILLPAY_VOLUME100': {
    'checkPay': 50,
    'quickPay': 50,
    'tierTitle': 'Professional 100',
    'tierName': 'Professional 100',
    'promoTierTitle': 'N/A',
    'productLineName': 'Bill Manager',
    'tier': 'volume100'
  },
  'BILLPAY_VOLUME200': {
    'checkPay': 100,
    'quickPay': 100,
    'tierTitle': 'Professional 200',
    'promoTierTitle': 'N/A',
    'productLineName': 'Bill Manager',
    'tierName': 'Professional 200',
    'tier': 'volume200'
  }
};

export const getBmTierData = (tier) => {
  const tierExists = BM_ENTITLEMENT_TIERS.includes(tier);
  if(tierExists) {
    return BM_TIER_DATA[tier];
  } else {
    console.warn('getBmTierData recieved invalid BM tier: '+ tier); 
    return {checkPay: 'N/A', quickPay: 'N/A'};
  }
};

export const getMaxEntitlement = (globalState) => {
  const COUNTRY = `quicken-${globalState.country}`;
  const USER_ENTITLEMENTS = globalState.subscriptions[COUNTRY]?.allEntitlements;
  const USER_BM_ENTITLEMENTS = USER_ENTITLEMENTS.filter((ent) => BM_ENTITLEMENT_TIERS.includes(ent));

  let maxEntitlement = -1;
  USER_BM_ENTITLEMENTS.forEach(ent => {
    const ENT_ORDER = BM_ENTITLEMENT_TIERS.indexOf(ent);
    if(ENT_ORDER > maxEntitlement) {
      maxEntitlement = ENT_ORDER;
    }
  });

  if(maxEntitlement >= 0) {
    return BM_ENTITLEMENT_TIERS[maxEntitlement];
  } else {
    console.warn('getMaxEntitlement did not return a valid value: ' + maxEntitlement);
    return false;
  }
};

// Deprecate this function for the above functions so we do all the logic here instead of calling this function with specific tier checks across of a bunch of different components
// Promotional subscriptions still use this currently so those need to be ported then delete these.
export const getBMHasEntitlement = (globalState, entitlements = []) => {
  const COUNTRY = `quicken-${globalState.country}`;
  return globalState.subscriptions[COUNTRY]
    ?.allEntitlements
    ?.some(arg => entitlements.includes(arg)) ?? false;
};

export const getBMHasPromoEntitlement = (globalState) => {
  return ('billmanager-promotion' in globalState?.subscriptions);
};

export const getBMPlan = (globalState) => {
  const SUB = globalState?.subscriptions?.['billmanager'];
  const MAX_ENT = getMaxEntitlement(globalState);
  const MAX_ENT_DATA = getBmTierData(MAX_ENT);

  if (SUB?.allEntitlements) {
    return {
      current: SUB?.tierUriName,
      renewal: SUB?.renewalTierUriName ?? SUB?.tierUriName,
    };
  } else if (MAX_ENT) {
    return { current: MAX_ENT_DATA.tier, renewal: MAX_ENT_DATA.tier };
  } else {
    return {current: null, renewal: null};
  }
};

// else if (getBMHasPromoEntitlement(globalState)) {
//   const tier = globalState?.subscriptions?.['billmanager-promotion']?.tierUriName;
//   return { current: tier, renewal: tier };
// }

export const getBillManagerType = (globalState) => {
  if (globalState?.subscriptions?.billmanager) return 'PAID';
  else if (getBMHasEntitlement(globalState, BM_ENTITLEMENT_TIERS)) return 'FREE';
  else if (getBMHasPromoEntitlement(globalState)) return 'FREE';
  else return 'NONE';
};
