import React     from 'react';
import PropTypes from 'prop-types';

import * as S      from './payment-switcher.module.scss';
import CARD_ICON   from '../../../../assets/imgs/credit/card-icon.svg';
import PAYPAL_ICON from '../../../../assets/imgs/brand/paypal-icon.png';
import BANK_ICON   from '../../../../assets/imgs/icons/icon-bank2.svg';


const PaymentSwitcher = ({ method, setMethod, isCanada, isDefaultPayment=false}) => {
  return (
    <div className={S.paymentOptions}>
      <button
        type="button"
        onClick={() => setMethod('CARD')}
        className={method === 'CARD' ? S.active : ''}
      >
        <img src={CARD_ICON} alt="Credit Card"/>
          Card
      </button>
      <button
        type="button"
        onClick={() => setMethod('PAYPAL')}
        className={method === 'PAYPAL' ? S.active : ''}
      >
        <img src={PAYPAL_ICON} alt="PayPal Logo"/>
          PayPal
      </button>

      {(!isCanada && !isDefaultPayment )&&
        <button
          type="button"
          onClick={() => setMethod('ACH')}
          className={method === 'ACH' ? S.active : ''}
        >
          <img src={BANK_ICON} alt="ACH Logo"/>
            Bank
        </button>
      }
    </div>
  );
};

PaymentSwitcher.displayName = 'PaymentSwitcher';
PaymentSwitcher.propTypes   = {
  method:    PropTypes.string.isRequired,
  setMethod: PropTypes.func.isRequired,
  isCanada: PropTypes.bool.isRequired,
  isDefaultPayment: PropTypes.bool.isRequired,
};

export { PaymentSwitcher };
