import React from 'react';
import PropTypes from 'prop-types';

import * as S from './coterm-mini-sub-tile.module.scss';

// Components
import { ProductName } from '../../../common/product/prod-name';
import { Legal } from '../../../common/legal';

// import { Price } from '../../../common/product/price';
import { Expiry } from '../../../common/product/expiry';
import { AddonTile } from '../tile-addon-min/tile-addon-min';

// Context Functions
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import { useGlobalCtx } from '../../../../context/ctx-hook';
import { getBillManagerType } from '../../../../context/selectors/bill-selectors';
import { useAutoRenew } from '../../../../helpers/hooks/use-auto-renew';
import * as RouteHelpers from '../../../../helpers/route-helper';



const TileCoTermMiniSub = ({ sub, allSubsArOff, allSubsExpired }) => {
  const { state } = useGlobalCtx();
  const SUB_HAS_AR_OFF = sub.autoRenew === false;
  const HAS_QOB = !!state.subscriptions?.['quicken-online-backup'];
  const BM_TYPE = getBillManagerType(state);
  const HAS_ADDONS = ['quicken-us', 'quicken-ca'].includes(sub?.productLineUriName) && (HAS_QOB || BM_TYPE != 'NONE');
  // const QKN_SUB = ProductSelectors.getSubscription(state, (state.country === 'us') ? 'quicken-us' : 'quicken-ca');
  const handleAROn = useAutoRenew(sub.productLineUriName, true);
  const PRODUCT_EXPIRED = sub.active === false;
  const QKN_SUB = ProductSelectors.getSubscription(state, state.country === 'us' ? 'quicken-us' : 'quicken-ca');
  const QKN_IS_ACTIVE = QKN_SUB?.autoRenew;
  
  // const PRODUCT_EXPIRED = true;
  // console.log(sub);
  

  return (
    <div className={S.subTileMini}>
      <div className={S.subTileMiniHeader} >
        {SUB_HAS_AR_OFF && !allSubsArOff &&
          <Expiry sub={sub} product={sub.productLineUriName} className={S.productExpiry} />
        }

        <ProductName sub={sub} product={sub.productName} className={S.miniSubName} nameStyle='inline' />

        {sub.active && SUB_HAS_AR_OFF &&
          <>
            <button
              type="button"
              data-testid="QKN_RESTART_SUB"
              onClick={handleAROn}
              className={S.ctaBtn}
            >Resume Subscription</button>
            <Legal className={S.legal} />
          </>
        }

        {!allSubsExpired && !QKN_IS_ACTIVE && PRODUCT_EXPIRED && 
          <a
            data-testid="LINK_RENEW_QKN"
            className={S.ctaBtn}
            href={RouteHelpers.getEStoreCartUrlAddProduct(sub.renewalSku, state.country)}
          >Buy Now</a>
        }
      </div>

      {HAS_ADDONS &&
        <div className={S.subTileMiniBtm} >
          {/* <span className={S.dash} /> */}
          <span className={`${S.dash} ${S.miniSub}`} />
          
          <p className={S.addonTitle}>Add-ons</p>

          <div className={S.addonContain}>
            {BM_TYPE == 'FREE' &&
              <AddonTile
                // isModal={isModal}
                qknIsActive={QKN_IS_ACTIVE}
                product={'billmanager'}
                // sub={QKN_SUB}
                // qknHasArOn={autoRenew}
              />
            }

            {BM_TYPE == 'PAID' &&
              <AddonTile
                // isModal={isModal}
                qknIsActive={QKN_IS_ACTIVE}
                // sub={QKN_SUB}
                product={'billmanager'}
                allSubsArOff={allSubsArOff}
                allSubsExpired={allSubsExpired}
                // qknHasArOn={autoRenew}
              />
            }

            {HAS_QOB &&
              <AddonTile
                // isModal={isModal}
                qknIsActive={QKN_IS_ACTIVE}
                product={'quicken-online-backup'}
                allSubsArOff={allSubsArOff}
                allSubsExpired={allSubsExpired}
                // sub={QKN_SUB}
                // qknHasArOn={autoRenew}
              />
            }
          </div>
        </div>
      }
    </div>


  );
};

TileCoTermMiniSub.displayName  = 'TileCoTermMini';
TileCoTermMiniSub.propTypes    = {
  sub: PropTypes.object,
  allSubsArOff: PropTypes.bool,
  allSubsExpired: PropTypes.bool

};
TileCoTermMiniSub.defaultProps = {
  sub: {},
  allSubsArOff: false,
  allSubsExpired: false
};


export { TileCoTermMiniSub };