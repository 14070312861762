import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S    from './card-form.module.scss';
import VISA_ICON from '../../../../assets/imgs/credit/visa-icon.svg';
import DISC_ICON from '../../../../assets/imgs/credit/disc-icon.svg';
import MC_ICON   from '../../../../assets/imgs/credit/mc-icon.svg';
import AMEX_ICON from '../../../../assets/imgs/credit/amex-icon.svg';

const HOSTED_FIELDS = [
  {label: 'Card number', id: 'simp-cc-number', name: 'number'},
  {label: 'CVV', id: 'simp-cvv', name: 'cvv'},
  {label: 'Name', id: 'simp-cc-name', name: 'cardholderName'},
  {label: 'Expiration Date', id: 'simp-cc-expires', name: 'expirationDate'},
  {label: 'Zip code', id: 'simp-cc-postal', name: 'postalCode'}
];

const CCForm = ({isVisible, errors}) => {
  return (
    <article className={ClassNames(S.cardWrapper, {[S.show]: isVisible})}>

      <div className={S.cardRow}>
        <img src={VISA_ICON} alt="Visa Card"/>
        <img src={MC_ICON} alt="Master Card"/>
        <img src={AMEX_ICON} alt="American Express Card"/>
        <img src={DISC_ICON} alt="Discover Card"/>
      </div>

      <div className={S.inputArea}>
        {HOSTED_FIELDS.map(({id, label, name}) => (
          <div key={id} className={S.inputField}>
            <label htmlFor={id}>{label}</label>
            <div id={id} className={ClassNames({[S.inputError]: errors[name]})}/>
            <p className={S.error}>{errors[name]}</p>
          </div>
        ))}
      </div>
      <p className={S.ppHeading}>This payment method will be set as your default payment method.</p>
    </article>
  );
};

CCForm.displayName = 'CCForm';
CCForm.propTypes   = {
  isVisible:  PropTypes.bool.isRequired,
  errors:     PropTypes.shape({
    number:         PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
    cvv:            PropTypes.string.isRequired,
    cardholderName: PropTypes.string.isRequired,
    postalCode:     PropTypes.string.isRequired
  })
};

export { CCForm };
