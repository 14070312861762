import React     from 'react';
import PropTypes from 'prop-types';

import * as S            from './plan-box-qkn.module.scss';
import { Price }         from '../../../common/product/price';
import { ProductName }   from '../../../common/product/prod-name';
// import { TagLine }       from '../../../common/product/tag-line';
import * as Actions      from '../../../../context/ctx-actions';
import { useGlobalCtx }  from '../../../../context/ctx-hook';
import { MODALS }        from '../../../../helpers/constants/state-constants';
import * as RouteHelpers from '../../../../helpers/route-helper';


const PlanBoxQkn = ({ sku, name, action, country, currentTierUri, renewalTierUriName=false, uriName, boxText }) => {
  const {globalDispatch, state} = useGlobalCtx();
  const TEST_TAG       = uriName.toUpperCase();
  const product = country === 'us' ? 'quicken-us' : 'quicken-ca';
  // const PENDING_DOWNGRADE = 
  const PENDING_DOWNGRADE = renewalTierUriName ? renewalTierUriName !== currentTierUri : false;
  const IS_CURRENT_DOWNGRADE_TIER = renewalTierUriName === uriName;

  // console.log('currentTierUri: ' + currentTierUri);
  // console.log('renewalTierUriName: ' + renewalTierUriName);
  // console.log('uriName: ' + uriName);
  // console.log('PENDING_DOWNGRADE: ' + PENDING_DOWNGRADE);
  // console.log('_______________________');

  const openDowngradeConfirm = React.useCallback((currentProductLineUri, desiredProductLineUri) => 
    globalDispatch(Actions.setCtxNestedObj('modal', {
      type: MODALS.CONFIRM_DOWNGRADE,
      ctx: { product, currentProductLineUri, desiredProductLineUri },
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })), [globalDispatch, product]
  );

  let ctaObj = {isHidden: false, offerExtend: false};

  if (action === 'DOWNGRADE') {
    ctaObj = {
      ...ctaObj,
      text:       'Downgrade'
    };
  } else if (action === 'UPGRADE') {
    ctaObj = {
      ...ctaObj,
      text:       'Upgrade'
    };
  }
  
  return (
    <article className={`${S.option} ${uriName}`} data-testid={`PLAN_${TEST_TAG}`}>
      <ProductName customName={name} product='quicken' className={S.name} />

      <p className={S.tag}>{boxText}</p>

      <Price
        sku={sku}
        className={S.price}
        renewalFrequency="P1Y"
      />

      {action === 'UPGRADE' &&
        <div className={S.btnRow}>
          {!ctaObj.isDisabled && ( //enabled Link
            <a
              href={RouteHelpers.getEStoreCartUrlAddProduct(sku, country)}
              className={S.activeBtn}
              data-testid={`LINK_ESTORE_${TEST_TAG}`}
            >{ctaObj.text}</a>
          )}
        </div>
      }

      {action === 'DOWNGRADE' && !IS_CURRENT_DOWNGRADE_TIER &&
        <div className={S.btnRow}>
          {PENDING_DOWNGRADE &&
            <p className={S.cancelPendingMsg}>Cancel pending downgrade to switch to this tier.</p>
          }

          {!PENDING_DOWNGRADE &&
            <button
              onClick={() => openDowngradeConfirm(currentTierUri, uriName)}
              className={S.activeBtn}
              data-testid={`LINK_ESTORE_${TEST_TAG}`}
            >
              {ctaObj.text}
            </button>
          }

        </div>
      }
    </article>
  );
};

PlanBoxQkn.displayName = 'PlanBoxQkn';
PlanBoxQkn.propTypes   = {
  name:               PropTypes.string.isRequired,
  boxText:            PropTypes.string.isRequired,
  currentTierUri:     PropTypes.string.isRequired,
  renewalTierUriName: PropTypes.string,
  uriName:            PropTypes.string.isRequired,
  sku:                PropTypes.string.isRequired,
  // daysOut:            PropTypes.oneOf(['FAR', 'MID', 'NEAR']).isRequired,
  action:             PropTypes.oneOf(['CURRENT', 'UPGRADE', 'DOWNGRADE']).isRequired,
  country:            PropTypes.oneOf(['us', 'ca']).isRequired,
};

export { PlanBoxQkn };
