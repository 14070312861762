import React from 'react';

import * as S       from './subscription-view.module.scss';
import { TileQkn }  from './tile-qkn/tile-qkn';
import { TileQem }  from './tile-qem/tile-qem';
import { SimpTile } from './tile-simp/simp-tile';
import { TileCoTerm } from './tile-coterm/coterm-tile';
import { AdvertTile } from './tile-advert/advert-tile';
// import { Message }  from '../../common/message';
import { ViewWrapper }       from '../../common/view-wrapper';
import { useGlobalCtx }      from '../../../context/ctx-hook';
// import { getAccountMessage } from '../../../context/selectors/product-selectors';
import { useOnPageChange }   from '../../../helpers/hooks/use-on-page-change';
import { getBillManagerType } from '../../../context/selectors/bill-selectors';
// import { getSubscription }  from '../../../context/selectors/product-selectors';

const SubscriptionView = () => {
  const {state}   = useGlobalCtx();
  const IS_USA    = state.country === 'us';
  
  // Coterm tile
  let COTERM_SUBS = Object.values(state.subscriptions).filter(sub => sub.isCoTerminus);

  // Single Tiles
  // this HAS_QKN only checks for non-cotermed quickens
  const HAS_QKN = !!state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'] && !state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'].isCoTerminus;  
  const HAS_QEM = !!state.subscriptions?.['quicken-estate-manager'] && !state.subscriptions?.['quicken-estate-manager'].isCoTerminus;
  const SIMP_SUBS = Object.values(state.subscriptions).filter(sub =>{
    return sub.productLineUriName == 'acme' && !sub.isCoTerminus;
  });

  // Addon checks for advertisement tile
  const BM_TYPE = getBillManagerType(state);
  // Check if they have any quicken co-term on not
  const HAS_ANY_QKN = !!state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'];
  const HAS_QOB = !!state.subscriptions?.['quicken-online-backup'];
  const QKN_SUB_ACTIVE = Object.values(state.subscriptions).filter(sub => sub.productLineUriName === 'quicken-us' && sub.autoRenew === true && sub.active === true).length > 0;
  const SHOW_ADVERT = HAS_ANY_QKN && QKN_SUB_ACTIVE && IS_USA && (BM_TYPE === 'NONE' || !HAS_QOB);
  const PRODUCT_LINE_ADS = [];
  if(BM_TYPE === 'NONE') PRODUCT_LINE_ADS.push('billmanager');
  if (!HAS_QOB) PRODUCT_LINE_ADS.push('quicken-online-backup');


  // Stubbed info to fix the fact that quicken and online backup are technically 'coterms' but we only want to show them as a single product with addons. Some API changes are being worked on to replace this loigc (maybe)
  const COTERM_IS_QKN_AND_QOB = COTERM_SUBS.map(sub => ['quicken-us', 'quicken-ca', 'quicken-online-backup'].includes(sub.productLineUriName));
  const ACT_LIKE_COTERM_IS_NOT_COTERM = COTERM_SUBS.length === COTERM_IS_QKN_AND_QOB.length && COTERM_IS_QKN_AND_QOB.every(value => value === true);
  const QUICKEN_SUB = ACT_LIKE_COTERM_IS_NOT_COTERM ? HAS_ANY_QKN : HAS_QKN;
  if (ACT_LIKE_COTERM_IS_NOT_COTERM) {
    COTERM_SUBS = [];
  }

  // console.log('HAS_ANY_QKN: ' + HAS_ANY_QKN);
  // console.log('HAS_QOB: ' + HAS_QOB);
  // console.log('BM_TYPE: ' + BM_TYPE);
  // console.log('COTERM_IS_QKN_AND_QOB: ' + COTERM_IS_QKN_AND_QOB);
  // console.log('ACT_LIKE_COTERM_IS_NOT_COTERM: ' + ACT_LIKE_COTERM_IS_NOT_COTERM);
  // console.log('QUICKEN_SUB: ' + QUICKEN_SUB);
  // console.log('SHOW_ADVERT: ' + SHOW_ADVERT);
  // console.log('HAS_ANY_QKN: ' + HAS_ANY_QKN);
  // console.log('IS_USA: ' + IS_USA);
  // console.log('BM_TYPE: ' + BM_TYPE);
  // console.log('SHOW_ADVERT: ' + SHOW_ADVERT);
  // console.log('QKN_SUB_ACTIVE: ');
  // console.log(QKN_SUB_ACTIVE);
  // console.log('PRODUCT_LINE_ADS: ');
  // console.log(PRODUCT_LINE_ADS);

  useOnPageChange();

  return (
    <ViewWrapper testId="SUBSCRIPTION_AREA" title="Subscriptions">
      <div className={S.boxArea}>
        {COTERM_SUBS.length > 0 && <TileCoTerm subs={COTERM_SUBS} />}
        {/* {HAS_QKN && <TileQkn/>} */}
        {QUICKEN_SUB && <TileQkn/>}
        {IS_USA && SIMP_SUBS.length > 0 && (
          <div className={S.simpArea}>
            {SIMP_SUBS.map(sub => <SimpTile key={sub} product={sub.productLineUriName}/>)}
          </div>
        )}
        {HAS_QEM && <TileQem/>}

        {SHOW_ADVERT &&
          <AdvertTile 
            productLineAds={PRODUCT_LINE_ADS}
          />
        }
      </div>
    </ViewWrapper>
  );
};

SubscriptionView.displayName = 'SubscriptionView';

export { SubscriptionView };
