import React     from 'react';
import PropTypes from 'prop-types';

import * as S                from './subscription-modal.module.scss';
// import { SubPaymentMethod }  from './sub-payment-area';
// import { SubHistory }        from './sub-payment-history';
import { InfoAcme }          from './info-acme/info-acme';
import { InfoQuicken }       from './info-quicken/info-quicken';
import { InfoBm }            from './info-bm/info-bm';
import { InfoQem }           from './info-qem/info-qem';
import { InfoQob }           from './info-qob/info-qob';
import { ModalWrapper }      from '../modal-wrapper';
import { ForceClose }        from '../force-close';
import { getProductsPaymentMethod } from '../../../context/selectors/user-selectors';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import { getSubscription }   from '../../../context/selectors/product-selectors';
import { Expiry }            from '../../common/product/expiry';


const InfoCard = (props) => {
  // console.log(props);
  // if (props.product === 'billmanager') return <InfoBm {...props} />;
  if (props.product.includes('quicken-estate-manager')) return <InfoQem {...props} />;
  // else if (props.product.includes('quicken-online-backup')) return <InfoQob {...props} />;
  else if (['quicken-us', 'quicken-ca'].includes(props.product)) return <InfoQuicken {...props} />;
  else if (props.product.includes('acme')) return <InfoAcme {...props} />;
  else return null;
};


const SubscriptionModal = ({onClose, ctx: {product}}) => {
  const {state} = useGlobalCtx();
  // const SUB     = getSubscription(state, product);
  if (product.length <= 0) return <ForceClose onClose={onClose}/>;
  // console.log(product);

  const HAS_PAYMENT = getProductsPaymentMethod(product, state);
  // console.log(product);

  return (
    <ModalWrapper heading="Manage Subscription" onClose={onClose}>
      <div className={S.modalBody}>
        <div className={S.header}>
          <h3 className={S.heading}>Your Subscription</h3>

          {/* {product.length > 1 &&
            <Expiry product={product[0].productLineUriName} className={S.cotermExpiry}/>
          } */}
        </div>

        {product.map((sub, i) => {
          const product = sub.productLineUriName;
          return (
            <div key={i}>
              {InfoCard({ product, onClose })}
            </div>
          );
        })}


        {/* {HAS_PAYMENT && (
          <>
            <h3 className={S.heading}>Payment Info</h3>
            <SubPaymentMethod product={product}/>
          </>
        )} */}
        {/* <h3 className={S.heading}> Payment History</h3> */}
        {/* <SubHistory product={product}/> */}
      </div>
    </ModalWrapper>
  );
};

SubscriptionModal.displayName = 'SubscriptionModal';
SubscriptionModal.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.array.isRequired
  })
};

export { SubscriptionModal };
