import React from 'react';
import PropTypes from 'prop-types';
import * as S from './paymentRow.module.scss';
import * as PaymentHelpers from '../../../helpers/payment-helpers';
import { useGlobalCtx } from '../../../context/ctx-hook';
import { setCtxNestedObj } from '../../../context/ctx-actions';

import SM_PAYPAL from '../../../assets/imgs/brand/paypal-icon.png';
import BankIcon from '../../../assets/imgs/icons/icon-bank2.svg';
import ErrIcon from '../../../assets/imgs/icons/icon-info-red.svg';
import { MODALS } from '../../../helpers/constants/state-constants';

const productNameMap = (product) => {
  switch (product) {
    case 'quicken-us':
    case 'quicken-ca':
      return 'Quicken Classic payment';
    case 'billmanager':
      return 'Bill Manager payment';
    case 'quicken-online-backup':
      return 'Quicken Online Backup payment';
    case 'acme':
      return 'Quicken Simplifi payment';
    case 'quicken-estate-manager':
      return 'Quicken Lifehub payment';
    default:
      return '';
  }
};

const getPaymentImg = (payment) => {
  switch (payment?.paymentMethodType) {
    case 'ACH':
      return {
        src: BankIcon,
        altTag: 'Bank Icon'
      };
    case 'CREDIT_CARD':
      return {
        src: PaymentHelpers.getCCLogo(payment?.ccType),
        altTag: 'Bank Icon'
      };
    case 'PAYPAL':
      return {
        src: SM_PAYPAL,
        altTag: 'Bank Icon'
      };
    default:
      return {
        src: PaymentHelpers.getCCLogo(),
        altTag: 'Generic Icon'
      };
  }
};

const getBadgeText = (payment, product) => {
  if(payment.defaultMethod === true) {
    return 'Default payment';
  }
  if (payment?.defaultForProductLines?.length > 0) {
    return `${productNameMap(product)}`;
  }
};

const getProductLine = (payment, product) => {
  if (payment.defaultMethod === true) {
    return 'default-payment';
  } else if (payment?.defaultForProductLines?.length > 0) {
    return payment.defaultForProductLines[0];
  }
};

const PaymentRow = ({payment, product}) => {
  const {globalDispatch, state} = useGlobalCtx();
  const paymentType = payment.paymentMethodType;
  // const product = getProductLine(payment);
  // payment.isHardDeclined = true;
  const paymentIssue = payment?.isHardDeclined;

  const handleUpdatePayment = React.useCallback(() => {
    globalDispatch(setCtxNestedObj('modal', {
      type:      MODALS.UPDATE_PAYMENT,
      isLoading: true,
      hasError:  false,
      isDisabled: false,
      ctx:       {
        product,
        paymentOnly: true
      }
    }));
  }, [globalDispatch, product]);

  return (
    <div className={S.curPaymentRow}>
      <div className={S.payRowLeft}>
        <div className={S.imgContain}>
          <img
            role="presentation"
            className={S.card}
            src={getPaymentImg(payment).src}
            alt={getPaymentImg(payment).altTag}
          />
        </div>
        <span className={S.last4}> 
          { paymentType === 'ACH' && `****${payment.achLast4}` }
          { paymentType === 'CREDIT_CARD' && `****${payment.ccLast4}` }
        </span>

        <span className={S.productLineBadge}>
          {getBadgeText(payment, product)}
        </span>
      </div>


      <div className={S.payRowRight}>        
        <button
          type="button"
          className={S.linkBtn}
          onClick={handleUpdatePayment}
          disabled={state.isDisabled}
        >Update Payment
        </button>

        {paymentIssue &&
          <img
            role="presentation"
            className={S.errIcon}
            src={ErrIcon}
            alt={'Error icon'}
          />
        }
      </div>
    </div>
  );
};


PaymentRow.displayName = 'PaymentRow';
PaymentRow.propTypes   = {
  payment: PropTypes.object,
  product: PropTypes.string
};

export { PaymentRow };






