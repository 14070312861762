import mixpanel from 'mixpanel-browser';

import { MIX_PANEL_APP_ID } from './constants/constants';


export const MIX_PANEL_IDS = {
  ADDRESS:      'ChangeAddress',
  AUTO_RENEW:   'ChangeAutoRenew',
  REMIND_LATER: 'EnabledRemindLater',
  CC_CHANGE:    'ChangeCreditCard',
  MFA:          'ChangePreferredMfaChannel',
  NAME:         'ChangeName',
  PASSWORD:     'ChangePassword',
  PHONE:        'ChangePhone',
  QUICKEN_ID:   'ChangeQuickenId',
  SIGN_OUT:     'SignOutOfAllDevices',
  DOWNGRADE:    'Downgrade'
};

export const initMixPanel = () => mixpanel.init(process.env.REACT_APP_MIX_PANEL);


export const track = (string, obj) => {
  if (!string) throw Error('MixPanel Error: Missing required field');

  return mixpanel.track(`${MIX_PANEL_APP_ID}:${string}`, obj);
};

export const error = (err, string) => {
  if (!err || !string) {
    throw Error('MixPanel Error: Missing required fields');
  }

  const {httpStatus, code} = err?.response?.data?.errors?.[0] ?? {httpStatus: 'UNKNOWN', code: 'UNKNOWN'};

  return track(string + 'Error', {
    errorCode:    httpStatus,
    errorSubCode: code
  });
};
