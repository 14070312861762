import React from 'react';
import PropTypes from 'prop-types';

import * as S from './advert-tile.module.scss';

import { useGlobalCtx } from '../../../../context/ctx-hook';

import { BmAdvert } from '../tile-bm/bm-advert';
import { QobAdvert } from '../tile-qob/qob-advert';


const AdvertTile = ({ productLineAds }) => {
  const { state } = useGlobalCtx();
  // const { productLineAds } = props;
  // console.log(productLineAds);
  // console.log(productLineAds[0]);
  // console.log(typeof(productLineAds));
  // console.log(productLineAds.includes('billmanager'));
  
  return (
    <div className={S.advertContain} data-testid="ADVERT_TILE">
      <div className={`${S.tile} advert`}>
        <div className={S.advertTitle}>
          <h3>More add-ons  that would be perfect for you ...</h3>
        </div>
        
        <span className={S.dash} />

        <div className={S.adverts} >
          {productLineAds.includes('billmanager') &&
            <BmAdvert />
          }

          {productLineAds.includes('quicken-online-backup') &&
            <QobAdvert />
          }
        </div>
      </div>
    </div>
  );
};

AdvertTile.displayName  = 'advertTile';
AdvertTile.propTypes    = {
  productLineAds: PropTypes.array
};

AdvertTile.defaultProps = {
  productLineAds: []
};

export { AdvertTile };