import React     from 'react';
import PropTypes from 'prop-types';

import * as S                from './info-bm.module.scss';
import { SubActionRow }      from '../sub-action-row';
import { Expiry }            from '../../../common/product/expiry';
import { Price }             from '../../../common/product/price';
import { ProductName }       from '../../../common/product/prod-name';
import { TagLine }           from '../../../common/product/tag-line';
import { useGlobalCtx }      from '../../../../context/ctx-hook';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import { BmTopToggle }       from '../info-bm/bm-top-up';
import * as BillSelectors      from '../../../../context/selectors/bill-selectors';
import * as UserSelectors from '../../../../context/selectors/user-selectors';
import * as Actions from '../../../../context/ctx-actions';
import { MODALS } from '../../../../helpers/constants/state-constants';
import { Quota } from '../../../common/product/quota';


const InfoBm = ({product, onClose}) => {
  const {globalDispatch, state} = useGlobalCtx();
  // console.log(state);
  const BM_TYPE = BillSelectors.getBillManagerType(state);
  const SUB = ProductSelectors.getSubscription(state, product);
  // console.log(BM_TYPE);
  // console.log(product);
  // console.log(SUB);
  // const { active, autoRenew, tierUriName, sku, tierName, renewalTierName, renewalFrequency, renewalTierUriName } = SUB;
  // const SKU         = ProductSelectors.uriNameToSku(tierUriName);

  const QKN_SUB = ProductSelectors.getSubscription(state, state.country == 'us' ? 'quicken-us' : 'quicken-ca');
  const qknIsActive = QKN_SUB?.active;
  // const NAME         = ProductSelectors.getProductName(state, product);
  const RENEWAL_NAME = SUB?.renewalTierName;
  const SAME_PLAN    = (SUB?.renewalTierName === undefined) || (SUB?.renewalTierName === SUB?.tierName);
  const PRICE_SKU    = ProductSelectors.uriNameToSku(SAME_PLAN ? SUB?.tierUriName : SUB?.renewalTierUriName);

  // FREE BM STUFF
  const BM_MAX_ENT = BillSelectors.getMaxEntitlement(state);
  const BM_ENT_DATA = BillSelectors.getBmTierData(BM_MAX_ENT);
  const IS_PROMO_ENT = BillSelectors.getBMHasPromoEntitlement(state);
  const BM_ENT = IS_PROMO_ENT ? ProductSelectors.getSubscription(state, 'billmanager-promotion') : false;
  const PRODUCT =  IS_PROMO_ENT ?
    'billmanager-promotion':
    'billmanager';
  const PRODUCT_PAYMENT = UserSelectors.getProductsPaymentMethod(PRODUCT, state);
  const handleChangePlan = React.useCallback(
    () => globalDispatch(Actions.setCtxNestedObj('modal', {
      type:       MODALS.CHANGE_PLAN,
      ctx:        {product: PRODUCT},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );
  // END BM FREE STUFF
  
  // console.log('BM_TYPE: ' + BM_TYPE);
  // console.log('SUB: ' + SUB);
  // console.log(SUB);
  // console.log('BM_MAX_ENT: ' + BM_MAX_ENT);
  // console.log('BM_ENT: ' + BM_ENT);
  // console.log('BM_ENT_DATA: ' + BM_ENT_DATA);
  // console.log('RENEWAL_NAME: ' + RENEWAL_NAME);
  // console.log(BM_ENT_DATA);

  return (
    <article className={`${S.card} ${BM_TYPE === 'PAID' ? SUB.tierUriName : BM_ENT_DATA.tier}`}>
      <div className={S.bmInfo}>
        <div className={S.infoLeft}>
          {BM_TYPE === 'PAID' &&
            <ProductName sub={SUB} product='bill manager' className={S.prodName}/>
          }

          {BM_TYPE != 'PAID' && BM_ENT &&
            <ProductName sub={BM_ENT} product='bill manager' className={S.qknName}/>
          }

          {BM_TYPE != 'PAID' && BM_ENT_DATA &&
            <ProductName sub={BM_ENT_DATA} product='bill manager' className={S.qknName} />
          }

          {BM_TYPE === 'PAID' ?
            <TagLine uriName={SUB?.tierUriName} className={S.tagLine}/> :
            <TagLine uriName={BM_ENT_DATA?.tier} className={S.tagLine}/>
          }

          {/* {BM_TYPE == 'PAID' ?
            <Quota sku={SKU} className={S.topQuota}/> :
            <div className={S.payCounts}>
              <p>Check Pay - {BM_ENT_DATA.checkPay} per month</p>
              <p>Quick Pay - {BM_ENT_DATA.quickPay} per month</p>
            </div>
          } */}

          {BM_TYPE === 'PAID' &&
            <div className={S.priceArea}>
              <Price
                sku={PRICE_SKU}
                renewalFrequency={SUB.renewalFrequency}
                className={S.priceRow}
              />
              {RENEWAL_NAME && (
                <div className={S.renewalName}>Renews at {SUB.renewalTierName || SUB.tierName}</div>
              )}
            </div>
          }


          {BM_TYPE === 'FREE' && !IS_PROMO_ENT &&
            <p className={S.benefit}>Included with Quicken {QKN_SUB.tierName}</p>
          }

        </div>

        <div className={S.infoRight}>
          {BM_TYPE === 'PAID' &&
            <Expiry product={product} className={S.infoExpiryRow} />
          }

          <div className={S.bmTilePayment}>
            <BmTopToggle
              custClass="tileToggle"
            />

            {/* {HAS_ACH && !IS_PROMO_ENT &&
            <SubPaymentMethod
              product={PRODUCT}
              custClass="tilePay"
            />
          } */}
          </div>
          {/* <BmTopToggle/> */}
        
        </div>
        
        

        
      </div>

      {BM_TYPE === 'PAID' &&
        <SubActionRow product={product} onClose={onClose} isAddon={true} />
      }

      {BM_TYPE !== 'PAID' && qknIsActive &&
        <div>
          <span className={S.dash} />
          <button
            type="button"
            onClick={handleChangePlan}
            className={S.bpCTA}
            data-testid="BTN_BM_BUY_MORE"
          >
            {IS_PROMO_ENT ?
              'Buy More Payments' :
              'Upgrade Plan'
            }
          </button>
        </div>
      }
    </article>
  );
};

InfoBm.displayName = 'InfoBm';
InfoBm.propTypes   = {
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { InfoBm };
