import React      from 'react';
import PropTypes  from 'prop-types';
import classNames from 'classnames';

import * as S              from './qob-advert.module.scss';
import { getEStoreCartUrlAddProduct } from '../../../../helpers/route-helper';
import { useGlobalCtx }        from '../../../../context/ctx-hook';
import { Price } from '../../../common/product/price';

import { TagLine } from '../../../common/product/tag-line';
import { QOB_REDIRECT_SKU } from '../../../../helpers/constants/constants';
import * as ProductSelectors  from '../../../../context/selectors/product-selectors';
// import CHAT_ICON from '../../../assets/imgs/icons/icon-chat.svg';
import BLURPLE_CHECK from '../../../../assets/imgs/icons/circle-check-blurple.svg';


const PRODUCT_TIER_URI = 'qob-basic';
const PRODUCT = 'quicken-online-backup';

const QobAdvert = () => {
  const {state} = useGlobalCtx();

  const SKU = QOB_REDIRECT_SKU;
  const PRICE =  ProductSelectors.getPriceBySku(state, SKU);
  const renewalFrequency = 'P1Y';
  const country = state.country === 'us' ? 'us' : 'ca';

  return (
    <div className={S.qobTile}>
      <div className={S.cardTop}>
        <h3 className={S.productName}> <span className={S.primaryHighlight}>Quicken</span> Online Backup</h3>
        <TagLine uriName={'qob-basic'} className={S.qknTagLine} />
        <Price price={PRICE} product={PRODUCT} renewalFrequency={renewalFrequency} className={S.price} />
      </div>

      <ul className={S.featureList}>
        <li>
          <span className={S.checkmark}><img src={BLURPLE_CHECK} alt="Checkmark Icon" /></span>
          Built-in backup solution for Classic
        </li>
        <li>
          <span className={S.checkmark}><img src={BLURPLE_CHECK} alt="Checkmark Icon" /></span>
          Easy to use: automatic or manual one-click backup
        </li>
        <li>
          <span className={S.checkmark}><img src={BLURPLE_CHECK} alt="Checkmark Icon" /></span>
          Easy to restore: same process as current local backup
        </li>
      </ul>

      <div className={S.qobNote}>*Pay only for the days you use with Online Backup&apos;s prorated pricing</div>

      <span className={S.dash}/>

      <a
        type="button"
        href={getEStoreCartUrlAddProduct(SKU, country)}
        className={S.contentCTABtn}
        data-testid="BTN_QOB_PURCHASE"
      >Purchase Online Backup</a>
    </div>
  );
};

QobAdvert.displayName  = 'qobAdvert';
QobAdvert.propTypes    = {isModal: PropTypes.bool, listPosition: PropTypes.number};
QobAdvert.defaultProps = {isModal: false, listPosition: 0};

export { QobAdvert };
