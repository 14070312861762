import React     from 'react';
import PropTypes from 'prop-types';

import { AchModal }           from './ach-modal/ach-modal';
import { PaymentModal }       from './payment-modal/payment-modal';
import { ForceClose }         from '../force-close';
import { useGlobalCtx }       from '../../../context/ctx-hook';
import * as ProductSelectors  from '../../../context/selectors/product-selectors';
import { getBillManagerType } from '../../../context/selectors/bill-selectors';
import * as UserSelectors     from '../../../context/selectors/user-selectors';


const UpdatePayment = ({ onClose, ctx: { product, plan, bmSku, paymentOnly, acmeAction = {}}}) => {
  const {state}             = useGlobalCtx();
  const ACH_CTX             = {product, paymentOnly, plan, bmSku};
  const PAYMENT_CTX         = {product, paymentOnly};
  const {quicken, simplifi} = ProductSelectors.getProducts(state);
  const BM_TYPE             = getBillManagerType(state);
  let sku                   = null;
  let renewalSku            = null;

  if (state.subscriptions[product]) {
    sku = (product in state.subscriptions) ? state.subscriptions[product] : null;
    renewalSku = (product in state.subscriptions) ? state.subscriptions[product].renewalSku : null;
  }

  // console.log(product);
  if (product === 'default-payment') {
    return <PaymentModal onClose={onClose} ctx={{ ...PAYMENT_CTX}} />;
  } else if (product === 'billmanager') {
    return <AchModal onClose={onClose} ctx={ACH_CTX}/>;
  } else if (quicken && ['quicken-us', 'quicken-ca'].includes(product)) {
    return <PaymentModal onClose={onClose} ctx={{...PAYMENT_CTX, sku}}/>;
  } else if (simplifi && 'acme' === product) {
    return <PaymentModal onClose={onClose} ctx={{...PAYMENT_CTX, acmeAction, sku: renewalSku}}/>;
  } else if (product === 'quicken-estate-manager'){
    return <PaymentModal onClose={onClose} ctx = {{...PAYMENT_CTX, sku: renewalSku}}/>;
  } else {// THIS is here only because of url parameters loc=update-payment without a product_line query-param
    const PRODUCT_PAYMENT     = UserSelectors.getProductsPaymentMethod(product , state);
    const HAS_ACH_PAYMENT     = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;
    const HAS_PAYMENT     = UserSelectors.getProductsPaymentMethod(product, state);


    if (quicken) {
      const {autoRenew} = ProductSelectors.getSubscription(state, quicken);

      if (!autoRenew || !HAS_PAYMENT) {
        return <PaymentModal
          onClose={onClose}
          ctx={{...PAYMENT_CTX, product: quicken, sku}}
        />;
      }
    }

    if (simplifi) {
      const {autoRenew} = ProductSelectors.getSubscription(state, simplifi);

      if (!autoRenew || !HAS_PAYMENT) {
        return <PaymentModal onClose={onClose} ctx={{...PAYMENT_CTX, product: simplifi, sku: renewalSku}}/>;
      }
    }

    if (BM_TYPE === 'PAID' && product === 'billmanager') {
      const {autoRenew} = ProductSelectors.getSubscription(state, 'billmanager');

      if (!autoRenew || !HAS_ACH_PAYMENT) {
        return <AchModal onClose={onClose} ctx={ACH_CTX}/>;
      }

      return <ForceClose onClose={onClose}/>;
    }

    return <ForceClose onClose={onClose}/>;
  }
};

UpdatePayment.displayName = 'UpdatePayment';

UpdatePayment.propTypes = {
  ctx:     PropTypes.object,
  onClose: PropTypes.func.isRequired
};

UpdatePayment.defaultProps = {
  ctx: null
};

export { UpdatePayment };
