import { Redirect, Route, Switch, useHistory } from 'react-router';

import ClassNames           from 'classnames';
import { parse, stringify } from 'query-string';
import React                from 'react';


import { MainHeader }       from './main-header';
import { Footer }           from './footer/footer-rebrand';
import * as S               from './main-page.module.scss';
import { DebuggingArea }    from './debugging';
import { NavList }          from './nav-list';
import { OverViewArea }     from './overview/overview';
import { PersonalView }     from './personal-info/personal-info';
import { SecurityView }     from './security-info/security-info';
import { PaymentView }      from './payments/payments';
import { SubscriptionView } from './subscriptions/subscription-view';
import { useGlobalCtx }     from '../../context/ctx-hook';
import { setCtxNestedObj }  from '../../context/ctx-actions';
import { getModalAssist }   from '../../helpers/payment-helpers';
import { ROUTES }           from '../../helpers/constants/constants';
import { MODALS }           from '../../helpers/constants/state-constants';

const MainPage = () => {
  const {globalDispatch, state} = useGlobalCtx();
  const {push}                  = useHistory();

  const FIRST_NAME               = state.personalInfo.firstName ?? '';
  const HAS_FOOTER               = state.ui.hasFooter;
  const {isAvailable, isVisible} = state.debug;

  React.useEffect(() => {
    const { modal, loc, product_line, bmp, ctx, ...rest} = parse(window.location.search, {
      parseBooleans: true,
      parseNumbers:  true
    });
    // console.log(modal);
    if (modal) {
      globalDispatch(setCtxNestedObj('modal', {
        type: modal,
        ctx:  JSON.parse(ctx)
      }));
      push({
        pathname: ROUTES.SUBSCRIPTIONS,
        search:   `?${stringify(rest)}`
      });
    } else if (loc) {
      const MODAL = getModalAssist({ loc, product_line, bmp});
      // console.log(MODAL);

      if(MODAL?.type == 'PHONE') {
        globalDispatch(setCtxNestedObj('modal', MODAL));
        push({
          pathname: ROUTES.SECURITY,
          search: `?${stringify(rest)}`
        });
      } else if (MODAL?.type == 'SUBSCRIPTION') {
        // globalDispatch(setCtxNestedObj('modal', MODAL));
        push({
          pathname: ROUTES.SUBSCRIPTIONS,
          search: `?${stringify(rest)}`
        });
      } else if (MODAL?.type == 'UPDATE_PAYMENT') {
        // globalDispatch(setCtxNestedObj('modal', MODAL));
        push({
          pathname: ROUTES.PAYMENTS,
          search: `?${stringify(rest)}`
        });
      } else if (MODAL) {
        globalDispatch(setCtxNestedObj('modal', MODAL));
        push({
          pathname: ROUTES.SUBSCRIPTIONS,
          search:   `?${stringify(rest)}`
        });
      }
    } else {
      globalDispatch(setCtxNestedObj('modal', {
        type:      MODALS.ALERT,
        ctx:       {},
        isLoading: true,
        hasError:  false
      }));
    }
  }, [globalDispatch, push]);

  return (
    <main className={S.pageLayout}>
      <MainHeader name={FIRST_NAME}/>

      <h1 className={S.greeting}>Welcome to your Account{FIRST_NAME ? `, ${FIRST_NAME}` : ''}.</h1>
      <div className={S.mainArea}>
        <aside className={ClassNames(S.leftSide, S.navWrapper)}>
          <h2 className={S.headingText}>My Account</h2>
          <NavList/>
        </aside>
        <Switch>
          <Route exact path={ROUTES.MAIN} component={OverViewArea}/>
          <Route exact path={ROUTES.SUBSCRIPTIONS} component={SubscriptionView}/>
          <Route exact path={ROUTES.PAYMENTS} component={PaymentView}/>
          <Route exact path={ROUTES.PERSONAL_INFO} component={PersonalView}/>
          <Route exact path={ROUTES.SECURITY} component={SecurityView}/>
          <Route path="/" component={() => <Redirect to={`${ROUTES.MAIN}${window.location.search}`}/>}/>
        </Switch>
      </div>
      {HAS_FOOTER && <Footer/>}
      {isAvailable && isVisible && <DebuggingArea/>}
    </main>
  );
};

MainPage.displayName = 'MainPage';

export { MainPage };
