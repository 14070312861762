export const HELP_PHONE_PREMIUM = '(888) 415-7170';
export const HELP_PHONE         = '(650) 250-1900';

export const MIX_PANEL_APP_ID = 'acctmgr';

export const URLS = {
  SIMPLIFI:      'https://www.simplifimoney.com',
  DOWNLOAD:      'https://download.quicken.com',
  SIMP_CANCEL_SURVEY: 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9IM8uA70AqWbtdJGbj1W0NrX8Ql1u9hI2O96Xycyfq4.js',
  SWITCH_SIMP_CANCEL_SURVEY: 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9fVHxWHA0MAbY_2F9TWaien_2BpT4lDB3d1jiBWxH_2Fp0MD7.js',
  NO_SWITCH_SIMP_CANCEL_SURVEY: 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9fVHxWHA0MAbY_2F9TWaien_2BGvwUD5FbG3lEV5eDDP0hg.js'
};

export const APP_STORES = {
  APPLE:  'https://apps.apple.com/us/app/simplifi-budget-money-app/id1449777194',
  GOOGLE: 'https://play.google.com/store/apps/details?id=com.quicken.acme',
  CHROMEBOOK: 'https://play.google.com/store/apps/details?id=com.quicken.acme'
};

export const APP_SUB_PAGE = {
  APPLE: 'itms-apps://apps.apple.com/account/subscriptions',
  GOOGLE: 'https://play.google.com/store/account/subscriptions'
};

export const ROUTES = {
  LOGIN:         '/login',
  LOGOUT:        '/logout',
  OLD_PAYMENT:   '/update-payment',
  MAIN:          '/main',
  SUBSCRIPTIONS: '/subscriptions',
  PERSONAL_INFO: '/personal',
  SECURITY:      '/security',
  PAYMENTS:      '/payment'
};

export const ACTION_TRANSLATIONS = {
  // N/A should never appear for a monetary transaction
  AUTO_RENEW:               'Auto-Renewed',
  MANUAL_RENEW:             'Renewed',
  EXTEND:                   'Extended',
  CREATE:                   'Purchased',
  ADDON_PURCHASE:           'Purchased',
  PRORATED_CREATE:          'Purchased',
  DOWNGRADE:                'Downgraded To',
  DOWNGRADE_DEFER:          'Downgraded To',
  UPGRADE:                  'Upgraded',
  UPGRADE_PRORATED_BILLING: 'Upgraded To',
  // DISABLE_AUTORENEW:     'N/A',
  // DOWNGRADE_MANUAL:      'N/A',
  // ENABLE_AUTORENEW:      'N/A',
  // EXPIRE:                'N/A',
  // HARD_CANCEL:           'N/A',
  // PROMO_EXTEND:          'N/A',
  // REVERT:                'N/A',
  // SET_EXPIRATION_MANUAL: 'N/A',
  // SOFT_CANCEL:           'N/A',
  // UPGRADE_MANUAL:        'N/A',
};

export const REFUND_LOOKUP = {
  REFUND:              'Refund: ',
  REFUND_CANCEL:       'Cancelled with Refund: ',
  REFUND_EXPIREADJUST: 'Plan Change / Refund: ',
  REFUND_REVERT:       'Plan Change / Refund: ',
  REFUND_UNCHANGED:    'Refund: '
};

export const BM_ACTIONS = {
  CREATE: 'CREATE',
  UPGRADE: 'UPGRADE',
  DOWNGRADE: 'DOWNGRADE',
};

export const SIMP_SKUS = {
  P_1M:     '170295',
  P_1Y:     '170304',
  P_1M_ALT: '170299',
  P_1Y_ALT: '170298',

  F_1M_v2: '170308',
  F_1Y_v2: '170309',
  P_1M_v2: '170297',
  P_1Y_v2: '170296',

  F_1M_v3: '170310',
  F_1Y_v3: '170311',
  P_1M_v3: '170312',
  P_1Y_v3: '170313',

  F_1M_EDU_TEX: '170323',
  P_1M_EDU_TEX: '170324',
  P_1Y_EDU_TEX: '170325',

  F_1M_EDU_MI: '170365',
  F_1Y_EDU_MI: '170365',
  P_1M_EDU_MI: '170367',
  P_1Y_EDU_MI: '170368',

  F_3M_EDU_MI_1M_P: '170412', //SimpliFi 3-month free for Michigan Monthly EDU
  F_3M_EDU_MI_1Y_P: '170413', //SimpliFi 3-month free for Michigan Annual EDU
  P_1Y_EDU_MI_1Y_P: '170414', // Simplifi paid for Michigan Annual EDU V2

  F_1M_EDU_OLM_1M_P: '170379',
  F_1M_EDU_OLM_1Y_P: '170381',
  P_1M_EDU_OLM:      '170380',
  P_1Y_EDU_OLM:      '170382',

  F_3M_EDU_JMU_1M_P: '170383',
  F_3M_EDU_JMU_1Y_P: '170385',
  P_1M_EDU_JMU:      '170384',
  P_1Y_EDU_JMU:      '170386',

  F_1M_EDU_BUF_1M_P: '170400',
  F_1M_EDU_BUF_1Y_P: '170402',
  P_1M_EDU_BUF:      '170401',
  P_1Y_EDU_BUF:      '170403',

  F_1M_EDU_ORG_1M_P: '170387',
  F_1M_EDU_ORG_1Y_P: '170389',
  P_1M_EDU_ORG:      '170388',
  P_1Y_EDU_ORG:      '170390'
};

export const QOB_REDIRECT_SKU = '170515';
