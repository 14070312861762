import classNames      from 'classnames';
import React           from 'react';
import { useLocation } from 'react-router';
import { Link }        from 'react-router-dom';
import PropTypes       from 'prop-types';
import { SlideDown }   from 'react-slidedown';

import * as S              from './main-header.module.scss';
import { NavList }         from './nav-list';
import LOGO_SIMPLIFY       from '../../assets/imgs/brand/logo-simplifi-full.svg';
// import QUICKEN_RED         from '../../assets/imgs/brand/qkn-red-full.svg';
import QUICKEN_REBRAND     from '../../assets/imgs/brand/logo-quicken.svg';
import QUICKEN_CA          from '../../assets/imgs/brand/qkn-ca.svg';
import { setCtxNestedObj } from '../../context/ctx-actions';
import { useGlobalCtx }    from '../../context/ctx-hook';
import { ROUTES }          from '../../helpers/constants/constants';
import { PAGE_STATUS }     from '../../helpers/constants/state-constants';

const getLabelText = path => {
  switch(path) {
    case ROUTES.MAIN: return 'Overview';
    case ROUTES.SUBSCRIPTIONS: return 'Subscriptions';
    case ROUTES.SECURITY: return 'Sign in & Security';
    case ROUTES.PERSONAL_INFO: return 'Personal Info';
  }
};


const MainHeader = ({name}) => {
  const {pathname} = useLocation();

  const SELECTED_TAB = React.useMemo(
    () => getLabelText(pathname),
    [pathname]
  );

  const {globalDispatch, state: {pageStatus, country, ui: {navListOpen, hideNav, embed}}} = useGlobalCtx();

  const handleSelection = React.useCallback(() => {
    globalDispatch(setCtxNestedObj('ui', {navListOpen: false}));
  }, [globalDispatch]);

  const handleOpenNav = React.useCallback(
    () => globalDispatch(setCtxNestedObj('ui', {navListOpen: !navListOpen})),
    [globalDispatch, navListOpen]
  );

  const handleCloseNav = React.useCallback(
    () => globalDispatch(setCtxNestedObj('ui', {navListOpen: false})),
    [globalDispatch]
  );
  const navHidden = (hideNav == 1 || embed == 1);



  return (
    <>
      {!navHidden && (
        <header className={S.mainHeader}>
          <div className={S.mainHeaderWrapper}>
            { (country === 'ca') &&
              <div className={S.brandSide}>
                <a href={process.env.REACT_APP_STORE_URL}>
                  <img src={ QUICKEN_CA } alt="Quicken Logo"/>
                </a>
              </div>
            }

            {
              (country === 'us') &&
              <div className={S.brandSide}>
                <a href={process.env.REACT_APP_STORE_URL}>
                  <img src={ QUICKEN_REBRAND } alt="Quicken Logo" />
                </a>
              </div>
            }

            <div className={S.userSide}>
              {name && <p>{name}</p>}
              <Link to={ROUTES.LOGOUT}>(Sign Out)</Link>
            </div>

          </div>
        </header>
      )}
      {pageStatus === PAGE_STATUS.READY && (
        <>
          <button
            data-testid="BTN_NAV_LIST"
            className={S.navBtn}
            onClick={handleOpenNav}
          >{SELECTED_TAB}</button>
          {navListOpen && (
            <>
              <SlideDown closed={!navListOpen} as="div" className={classNames(S.miniNav, {[S.hideNav]: hideNav})}>
                <NavList handleChange={handleSelection} />
              </SlideDown>
              <div
                role="presentation"
                className={S.overLay}
                onClick={handleCloseNav}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

MainHeader.displayName = 'MainHeader';
MainHeader.propTypes   = {
  name: PropTypes.string
};

MainHeader.defalutProps = {
  name: ''
};

export { MainHeader };
