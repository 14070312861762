import React     from 'react';
import PropTypes from 'prop-types';

import { AlertModal }          from './alert-modal/alert-modal';
import { CancelAR }            from './cancel-ar-modal/cancel-ar-modal';
import { SwitchToSimp }        from './cancel-ar-modal/switch-to-simp/switch-to-simp';
import { SwitchSuccess }       from './cancel-ar-modal/switch-to-simp/switch-success';
import { ChangePlanModal }     from './change-plans/change-plan';
import { ConfirmDowngrade }    from './change-plans/confirm-downgrade';
import { DropboxModal }        from './dropbox/dropbox-modal';
import { DropboxTermsModal }   from './dropbox/dropbox-terms-modal';
import { ReactivateSimpModal } from './change-plans/change-acme/reactivate-simp-modal';
import { SurveyModal }         from './cancel-ar-modal/survey-modal/survey-modal';
import { AddressModal }        from './personal-modals/address-modal';
import { NameModal }           from './personal-modals/name-modal';
import { MFAModal }            from './security/mfa-modal';
import { PasswordModal }       from './security/password-modal';
import { PhoneModal }          from './security/phone-modal';
import { QuickenIdModal }      from './security/quicken-id-modal';
import { SignOutModal }        from './security/sign-out-modal';
import { SubscriptionModal }   from './subscription-modal/subscription-modal';
import { UpdatePayment }       from './update-payment/update-payment';
import { Overlay }             from '../common/overlay/overlay';
import { mergeMainStateObj }   from '../../context/ctx-actions';
import { useGlobalCtx }        from '../../context/ctx-hook';
import { MODALS }              from '../../helpers/constants/state-constants';

const Modal = ({type, ctx}) => {
  const {globalDispatch} = useGlobalCtx();

  const handleClose = React.useCallback(
    () => globalDispatch(mergeMainStateObj({
      modal:      {type: null, ctx: null, isLoading: false, isDisabled: false},
      security:   {mfaSentChannel: '', mfaSentTo: ''},
      payment: {
        hasBraintreeNetError: null,
        btErrMsg: null,
        hasBraintreeError: null
      },
      isDisabled: false
    })),
    [globalDispatch]
  );

  return (
    <Overlay onClose={handleClose}>
      {type === MODALS.ALERT && <AlertModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.ADDRESS && <AddressModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.CANCEL_AR && <CancelAR onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.CHANGE_PLAN && <ChangePlanModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.CONFIRM_DOWNGRADE && <ConfirmDowngrade onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.MFA && <MFAModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.NAME && <NameModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.PASSWORD && <PasswordModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.PHONE && <PhoneModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.QUICKEN_ID && <QuickenIdModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.REACTIVATE && <ReactivateSimpModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.SIGN_OUT && <SignOutModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.SURVEY && <SurveyModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.SUBSCRIPTION && <SubscriptionModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.UPDATE_PAYMENT && <UpdatePayment onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.DROPBOX && <DropboxModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.DROPBOX_TERMS && <DropboxTermsModal onClose={handleClose} ctx={ctx}/>}
      {type === MODALS.SWITCH_TO_SIMP && <SwitchToSimp onClose={handleClose} ctx={ctx} />}
      {type === MODALS.SWITCH_SUCCESS && <SwitchSuccess onClose={handleClose} ctx={ctx} />}
    </Overlay>
  );
};

Modal.displayName = 'Modal';

Modal.propTypes = {
  ctx:  PropTypes.object,
  type: PropTypes.oneOf(Object.values(MODALS))
};

Modal.defaultProps = {
  ctx: null
};

export { Modal };
