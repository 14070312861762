import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import * as S from './tile-addon-min.module.scss';

import { ProductName }         from '../../../common/product/prod-name';
import { Expiry }              from '../../../common/product/expiry';
import { Legal }               from '../../../common/legal';
import { useGlobalCtx }        from '../../../../context/ctx-hook';
import * as BillSelectors      from '../../../../context/selectors/bill-selectors';
import * as ProductSelectors   from '../../../../context/selectors/product-selectors';
import { getBillManagerType }  from '../../../../context/selectors/bill-selectors';
import { useAutoRenew }        from '../../../../helpers/hooks/use-auto-renew';
import * as RouteHelpers from '../../../../helpers/route-helper';
import { getProductsPaymentMethod } from '../../../../context/selectors/user-selectors';
import * as StateConstants from '../../../../helpers/constants/state-constants';
import * as GlobalActions from '../../../../context/ctx-actions';
import * as NetworkCalls from '../../../../network/network-calls';
import * as Tealium from '../../../../helpers/tealium';
import { BM_ACTIONS } from '../../../../helpers/constants/constants';







// const AddonTile = ({ isModal, product, qknIsActive }) => {
const AddonTile = ({ product, allSubsArOff, allSubsExpired, qknIsActive }) => {
  const {globalDispatch, state} = useGlobalCtx();
  const SUB = ProductSelectors.getSubscription(state, product);
  const PRODUCT_PAYMENT = getProductsPaymentMethod(product, state);
  
  // BM special snowflake stuff
  const HAS_ACH         = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;
  const IS_BM           = ['billmanager', 'billmanager-promotion'].includes(product) ;
  const BM_TYPE         = getBillManagerType(state);
  const HAS_PAID_BM     = BM_TYPE == 'PAID';
  const BM_MAX_ENT      = BM_TYPE != 'NONE' ? BillSelectors.getMaxEntitlement(state) : false;
  const BM_ENT_DATA     = BM_MAX_ENT ? BillSelectors.getBmTierData(BM_MAX_ENT) : false;
  const IS_PROMO_ENT    = BillSelectors.getBMHasPromoEntitlement(state);
  const BM_ENT          = IS_PROMO_ENT ? ProductSelectors.getSubscription(state, 'billmanager-promotion') : false;
  const PRODUCT_AR      = SUB?.autoRenew;
  const PRODUCT_ACTIVE  = SUB?.active;
  const SHOW_EXPIRY     = qknIsActive && PRODUCT_ACTIVE;
  
  const handleUpdateBilling = React.useCallback(
    () => globalDispatch(GlobalActions.setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.UPDATE_PAYMENT,
      ctx:        {
        product:     product,
        plan:        SUB?.tierUriName ?? null,
        paymentOnly: false
      },
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, SUB?.tierUriName]
  );

  const reActivateSubscription = async () => {
    const {data} = await NetworkCalls.callPostChangeTier(SUB.tierUriName, SUB.sku, BM_ACTIONS.CREATE);
    // Tealium.tealiumLink(Tealium.BMTealiumEvent(state, data));
    Tealium.gtmLink(Tealium.BMTealiumEvent(state, data));

    globalDispatch(GlobalActions.mergeSubscriptionData(product, data.postState));
  };

  const handleAROn = useAutoRenew(product, true);
  const handleBuyNow = () => {
    if(SUB) {
      window.location.href = RouteHelpers.getEStoreCartUrlAddProduct(SUB.renewalSku, state.country);
    }
  };


  // console.log('product: ' + product);
  // console.log('SUB: ' + SUB);
  // console.log(SUB);
  // console.log('PRODUCT_ACTIVE: ' + PRODUCT_ACTIVE);
  // console.log('PRODUCT_AR: ' + PRODUCT_AR);
  // // console.log(state);
  // // console.log(qknIsActive && !PRODUCT_ACTIVE && IS_BM && BM_TYPE === 'PAID');
  // console.log('qknIsActive: ' + qknIsActive);
  // console.log('allSubsExpired: ' + allSubsExpired);
  // console.log('PRODUCT_ACTIVE: ' + PRODUCT_ACTIVE);
  // console.log('IS_BM: ' + IS_BM);
  // console.log('BM_TYPE: ' + BM_TYPE);
  // console.log('________________');




  return (
    <div className={S.addonMinTile} data-testid={'ADDON_TILE'} >
      {product == 'billmanager' &&
        // <ProductName product={product} className={S.qknName} customName={'Quicken Bill Manager'}/>
        // Ghetto fix for BM name, fix later
        <div className={S.qknName}> <span className={S.qknTheme}>Quicken </span>Bill Manager</div>
      }

      {SUB && product != 'billmanager' && 
        <ProductName sub={SUB} product={product} className={S.qknName} />
      }
      
      {!allSubsArOff && product == 'billmanager' && BM_TYPE == 'FREE' && !IS_PROMO_ENT &&
        <Expiry product={`quicken-${state.country}`} className={S.expiry} />
      }

      {!allSubsArOff && product == 'billmanager' && BM_TYPE == 'PAID' &&
        <Expiry product={'billmanager'} className={S.expiry} />
      }

      {!allSubsArOff && product == 'billmanager' && IS_PROMO_ENT &&
        <Expiry product={'billmanager-promotion'} className={S.expiry} />    
      }

      {!allSubsArOff && !allSubsExpired && product != 'billmanager' &&
        <Expiry product={SUB.productLineUriName} className={S.expiry} />
      }

      {qknIsActive && PRODUCT_ACTIVE && !PRODUCT_AR &&
        <>
          <button
            type="button"
            data-testid="QKN_RESTART_SUB"
            onClick={handleAROn}
            className={S.ctaBtn}
          >Resume Subscription</button>
          <Legal className={S.legal} />
        </>
      }

      {!allSubsExpired && qknIsActive && !PRODUCT_ACTIVE && !IS_BM && 
        <button
          data-testid="LINK_RENEW_QKN"
          className={S.ctaBtn}
          onClick={handleBuyNow}
        >Buy Now</button>
      }

      {qknIsActive && !PRODUCT_ACTIVE && IS_BM && BM_TYPE === 'PAID' && (
        <button
          type="button"
          onClick={(HAS_ACH && SUB) ? reActivateSubscription : handleUpdateBilling}
          className={S.bpCTA}
          data-testid="BTN_BM_RENEW_NOW"
        >Reactivate</button>
      )}
    </div>
  );
};

AddonTile.displayName  = 'AddonTile';
AddonTile.propTypes    = {
  // isModal: PropTypes.bool,
  // qknIsActive: PropTypes.bool,
  product: PropTypes.string,
  // listPosition: PropTypes.number,
  allSubsArOff: PropTypes.bool,
  allSubsExpired: PropTypes.bool,
  qknIsActive: PropTypes.bool
};
AddonTile.defaultProps = {
  // isModal: false,
  product: '',
  allSubsArOff: false,
// listPosition: 0
};

export { AddonTile };
